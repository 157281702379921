import { Box } from '@chakra-ui/react'
import React from 'react'

export default function Footer() {
  return (
    <Box>
    
    </Box>
  )
}
